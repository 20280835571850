<template>
  <div
    style="height: calc(100vh - 72px)"
    class="overflow-hidden hide-scrollbar"
  >
    <div class="bg-c-primary" v-if="selectedPrescription != null" id="printing">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <button
            type="button"
            class="
              btn
              text-white
              position-absolute
              btn-sm
              floating-btn
              d-flex
              text-arrow
            "
            @click="handleBack"
          >
            <!-- <img class="icon-svg mr-1" src="../../../assets/arrow-left.svg" /> -->
            <i class="ri-arrow-left-line mr-2 align-middle"></i>Back
          </button>
          <div class="text-center">
            <h4 class="text-white mb-0 ff-title fw-strong">
              E-prescription {{ selectedPrescription.prescription_name }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div
        class="
          overflow-hidden
          w-100
          d-flex
          justify-content-center
          align-items-start
          p-3
        "
        style="height: 70px"
      >
        <div>
          <div class="mb-4 text-center" id="printing">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
              v-if="selectedPrescription && selectedPrescription.consultation"
            >
              <button
                v-if="
                  selectedPrescription.consultation.prescriptions.length > 0
                "
                type="button"
                :class="
                  selectedTab == 'Prescription'
                    ? 'btn btn-danger btn-c-primary fw-strong'
                    : 'btn btn-outline-danger btn-outline-c-primary'
                "
                @click="selectedTab = 'Prescription'"
              >
                Prescription
              </button>
              <button
                type="button"
                v-if="
                  selectedPrescription.consultation.medical_certificates
                    .length > 0
                "
                :class="
                  selectedTab == 'Medical Certificate'
                    ? 'btn btn-danger btn-c-primary fw-strong'
                    : 'btn btn-outline-danger btn-outline-c-primary'
                "
                @click="selectedTab = 'Medical Certificate'"
              >
                Medical Certificate
              </button>
              <button
                type="button"
                v-if="
                  selectedPrescription.consultation.referral_letters.length > 0
                "
                :class="
                  selectedTab == 'Referral Letter'
                    ? 'btn btn-danger btn-c-primary fw-strong'
                    : 'btn btn-outline-danger btn-outline-c-primary'
                "
                @click="selectedTab = 'Referral Letter'"
              >
                Referral Letter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          overflow-auto
          w-100
          d-flex
          justify-content-center
          align-items-start
          px-3
        "
        style="height: calc(100vh - 204px); padding-bottom: 4rem"
      >
        <div v-if="!hasPaid">
          <div class="responsive-container d-flex justify-content-center">
            <div class="text-center mb-4">
              <div class="card" v-if="selectedPrescription != null">
                <div class="card-body border-bottom">
                  <div class="mb-2">
                    <h4 class="mb-2 ff-title fw-strong">Why wait?</h4>
                    <p class="mb-4 text-muted ff-desc">
                      You can access your prescriptions right here. Only after
                      making a payment may you access your prescription, medical
                      certificate, and referral letter, if any.
                    </p>
                  </div>
                  <div class="mb-2">
                    <p>
                      Only RM 15.00 for your expert
                      consultation
                    </p>
                    <!-- <p>
                      Only RM {{ consultationPrice.toFixed(2) }} for your expert
                      consultation
                    </p> -->
                  </div>
                  <button
                    class="
                      btn
                      mb-3
                      btn-primary
                      w-50
                      button-primary-color-bg
                      custom-radius
                    "
                    @click="handlePayment"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="selectedTab == 'Referral Letter'">
            <div
              v-if="
                selectedPrescription &&
                selectedPrescription.consultation &&
                !selectedPrescription.consultation.referral_letters.length &&
                !selectedPrescription.consultation.referral_letters[0]
                  .referral_letter_file
              "
              class="card border-0 custom-radius shadow-sm"
              style="max-width: 720px"
            >
              <div class="card-body text-center">
                <div class="d-flex justify-content-center w-100">
                  <div>
                    <div class="bg-waiting-img"></div>
                    <h5 class="ff-desc">
                      Please bear with us while the doctor is preparing your
                      related documents.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn btn-c-primary text-white mb-2 w-100"
                @click="
                  download(
                    selectedPrescription.consultation.referral_letters[0]
                      .referral_letter_file.url,
                    'referralLetter.pdf'
                  )
                "
              >
                Download PDF
              </button>
              <vue-pdf-embed
                v-if="referralLetterPDF"
                :source="referralLetterPDF"
              />
            </div>
          </div>
          <div v-if="selectedTab == 'Medical Certificate'">
            <div
              v-if="
                selectedPrescription &&
                selectedPrescription.consultation &&
                selectedPrescription.consultation.medical_certificates.length &&
                !selectedPrescription.consultation.medical_certificates[0]
                  .medical_certificate_file
              "
              class="card border-0 custom-radius shadow-sm"
              style="max-width: 720px"
            >
              <div class="card-body text-center">
                <div class="d-flex justify-content-center w-100">
                  <div>
                    <div class="bg-waiting-img"></div>
                    <h5 class="ff-desc">
                      Please bear with us while the doctor is preparing your
                      related documents.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn btn-c-primary text-white mb-2 w-100"
                @click="
                  download(
                    selectedPrescription.consultation.medical_certificates[0]
                      .medical_certificate_file.url,
                    'medicalCert.pdf'
                  )
                "
              >
                Download PDF
              </button>
              <vue-pdf-embed v-if="medicalCertPDF" :source="medicalCertPDF" />
            </div>
          </div>
          <div v-if="selectedTab == 'Prescription'">
            <div
              v-if="
                selectedPrescription && !selectedPrescription.prescription_file
              "
              class="card border-0 custom-radius shadow-sm"
              style="max-width: 720px"
            >
              <div class="card-body text-center">
                <div class="d-flex justify-content-center w-100">
                  <div>
                    <div class="bg-waiting-img"></div>
                    <h5 class="ff-desc">
                      Please bear with us while the doctor is preparing your
                      related documents.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn btn-c-primary text-white mb-1 w-100"
                 @click="
                  download(
                    selectedPrescription.prescription_file.url,
                    'prescription.pdf'
                  )
                "
              >
                Download PDF
              </button>
              <vue-pdf-embed v-if="prescriptionPDF" :source="prescriptionPDF" />
              <!-- Checkout Medication -->
              <!-- <button
                type="button"
                class="btn btn-c-primary text-white mt-3 w-100"
                @click="handleCheckout()"
              >
                Checkout Medication(s)
              </button> -->
              <!-- Checkout Medication -->
              <button
                v-if="selectedPrescription.orders && selectedPrescription.orders.length > 0"
                type="button"
                class="btn btn-c-primary text-white mt-3 w-100"
                @click="gotoOrderPage(selectedPrescription.orders[selectedPrescription.orders.length - 1].id)"
              >
                View Latest Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Confiq from "../../../../config";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import API from "../../../utils/API";
import moment from "moment";
import Helper from "../../../utils/helper";
import QrcodeVue from "qrcode.vue";
import { Html5QrcodeScanner } from "html5-qrcode";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CheckPrescription",
  components: {
    QrcodeVue,
    VuePdfEmbed,
  },
  data() {
    return {
      referralLetterPDF: null,
      medicalCertPDF: null,
      prescriptionPDF: null,
      config: Confiq,
      selectedTab: "Prescription",
      showModalAddtoCart: false,
      isProcessing: false,
      selectedPrescription: null,
      prescriptionDatasource: [],
      selectedDoc: null,
      keywords: "",
      noResult: null,
      timeout: 0,
      customerDataSource: null,
      helper: Helper,
      clinicData: null,
      froms: "check",
      consultationPrice: 15.00,
      hasPaid: false,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation", "cart"]),
  },
  watch: {
    keywords: {
      handler() {
        this.isProcessing = true;
        this.prescriptionDatasource = [];
        this.noResult = null;
        if (this.keywords.length > 0) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(async () => {
            await this.getPrescriptions(this.keywords);
          }, 1000);
        } else {
          this.isProcessing = false;
          this.prescriptionDatasource = [];
        }
      },
      immediate: false,
    },
  },
  methods: {
    ...mapActions(["setActiveConsultation", "createConsultationPayment"]),
    ...mapMutations(["addItem", "resetCart"]),
    convertDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    getImageLink(_url) {
      return API.getAPIUrl() + _url;
    },
    async handlePayment() {
      if(!this.consultation) {
        const response = await API.get(`/prescriptions/${this.$route.params.id}`);
        this.consultation = response.data;
      }

      let payloadData = {
        email: this.currentCustomer.user.email,
        phone: this.currentCustomer.user.phone,
        fullname: this.currentCustomer.user.username,
        description: `Consultation payment at ${moment().format(
          "dddd, D MMMM YYYY"
        )}`,
        testmode: true, // for now
        total_price: this.consultationPrice,
        total_tax: 0.0,
        consultation: this.consultation,
        transaction_type: "consultation",
      };
      this.createConsultationPayment(payloadData);
    },
    handleCheckout() {
      const dataSource = this.selectedPrescription.prescription_items;
      if (dataSource && dataSource.length > 0) {
        //clear all carts first
        let cart = this.cart
        cart.items = []
        cart.discount_code = null
        this.resetCart(cart)

        //then start insert item one by one
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].product) {
            this.addItemObj(dataSource[i]);
          }
        }
        this.goTo("/store/cart", { mode: 'e-prescription', 'e-prescription': this.selectedPrescription.id });
      }
    },
    gotoOrderPage(_orderID){
      this.$router.push({
        path: '/store/checkout/response',
        query: { payment: 'success', order: _orderID }
      });
    },
    addItemObj(_product) {
      this.addItem({
        product: _product.product,
        quantity: parseInt(_product.amount),
      }); //default value add of quantity is 1
    },
    getDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    goTo(url, query) {
      this.$router.push({
        path: url,
        query
      });
    },
    handleViewPrescription(data) {
      this.isProcessing = false;
      this.noResult = null;
      this.keywords = "";
      this.prescriptionDatasource = [];
      this.selectedPrescription = data;
      this.getCustomers(data);
      this.getClinic(data);
    },
    async getClinic(data) {
      if (data.doctor != undefined) {
        const res = await API.get(
          `/corporate-organisations/${data.doctor.corporate_organisation}`
        );
        if (res.status == 200) this.clinicData = res.data;
      }
    },
    handleBack() {
      if (this.froms == "consultations") {
        this.$router.push({
          path: "/e-prescription/list-prescription",
        });
        // this.$router.go(-1);
      } else {
        this.isProcessing = false;
        this.noResult = null;
        this.keywords = "";
        this.selectedPrescription = null;
        this.clinicData = null;
        this.prescriptionDatasource = [];
        this.customerDataSource = null;
      }
    },
    async getCustomers(data) {
      if (data.customer != undefined) {
        const res = await API.get(`/users/${data.customer.user}`);
        if (res.status == 200) {
          this.customerDataSource = res.data;
        }
      }
    },
    async getPrescriptions(string) {
      this.noResult = null;
      let url = "";
      let params = new URLSearchParams(url);
      params.set("prescription_name", string);
      // params.set("prescription_name", "#SCE-220207021801");
      const res = await API.get(`/prescriptions?${params.toString()}`);
      if (res.status == 200) {
        this.prescriptionDatasource = res.data;
        this.isProcessing = false;

        if (res.data.length == 0) {
          this.noResult = "Prescription Not Found.";
        }
      }
    },
    handlePrint() {
      window.print();
    },
    download(url, filename) {
      let files = this.config.API_URL + url;
      fetch(files)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      // console.log('1');
      const response = await API.get(`/prescriptions/${this.$route.params.id}`);
      const dataSource = response.data;
      if (dataSource) {
        const response2 = await API.get(
          `/consultations/${dataSource.consultation.id}`
        );
        this.froms = "consultations";
        this.selectedDoc = dataSource.doctor;
        this.getClinic(dataSource);
        this.selectedPrescription = {
          ...dataSource,
          consultation: response2.data,
        };

        if (!this.selectedPrescription.consultation.referral_letters)
          this.referralLetterPDF = null;
        if (
          this.selectedPrescription.consultation.referral_letters.length &&
          this.selectedPrescription.consultation.referral_letters[0]
            .referral_letter_file &&
          this.selectedPrescription.consultation.referral_letters[0]
            .referral_letter_file.url
        ) {
          this.referralLetterPDF =
            this.config.API_URL +
            this.selectedPrescription.consultation.referral_letters[0]
              .referral_letter_file.url;
        }

        if (!response2.data.prescription_file) this.prescriptionPDF = null;
        if (this.selectedPrescription.prescription_file) {
          this.prescriptionPDF =
            this.config.API_URL +
            this.selectedPrescription.prescription_file.url;
        }

        if (!this.selectedPrescription.consultation.medical_certificates)
          this.medicalCertPDF = null;
        if (
          this.selectedPrescription.consultation.medical_certificates.length &&
          this.selectedPrescription.consultation.medical_certificates[0]
            .medical_certificate_file &&
          this.selectedPrescription.consultation.medical_certificates[0]
            .medical_certificate_file.url
        ) {
          this.medicalCertPDF =
            this.config.API_URL +
            this.selectedPrescription.consultation.medical_certificates[0]
              .medical_certificate_file.url;
        }

        this.hasPaid =
          this.selectedPrescription.consultation.payment_status &&
          this.selectedPrescription.consultation.payment_status == "paid"
            ? true
            : false;
      }
    } else {
      // console.log(this.consultation);
      if (this.consultation) {
        // console.log('2');
        await this.setActiveConsultation({
          userID: this.currentCustomer.user.id,
        });
        if (
          this.consultation.prescriptions &&
          this.consultation.prescriptions.length > 0
        ) {
          this.froms = "consultations";
          this.selectedDoc = this.consultation.doctor;
          this.getClinic(this.consultation);
          this.selectedPrescription = {
            ...this.consultation.prescriptions[0],
            consultation: this.consultation,
          };
        }
      }
    }
    // console.log(this.selectedPrescription);
    // const html5QrcodeScanner = new Html5QrcodeScanner(
    //   "reader",
    //   { fps: 10, qrbox: { width: 250, height: 250 } },
    //   /* verbose= */ false
    // );

    // html5QrcodeScanner.render(
    //   (decodedText, decodedResult) => {
    //     // handle the scanned code as you like, for example:
    //     // console.log(`Code matched = ${decodedText}`, decodedResult);
    //     this.keywords = decodedText;
    //   },
    //   (error) => {
    //     // console.warn(`Code scan error = ${error}`);
    //   }
    // );
  },
};
</script>

<style scoped>
#reader__dashboard_section_csr div button {
  background-color: red;
}

.logo {
  position: relative;
  display: block;
  height: 80px;
  width: 120px;
}

.logo img {
  height: auto;
  width: 100%;
}

.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}
.btn,
a,
.card,
h4 {
  font-family: "Karla", serif !important;
}

.bg {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 133px);
  background-image: url(../../../assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
}

::-webkit-scrollbar {
  display: none !important;
}

.btn-small {
  font-size: 0.8rem !important;
  outline: none !important;
}

.btn-green {
  color: #155724 !important;
}

.small-title {
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.fw-bolder {
  font-weight: 500;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.text-arrow {
  display: block;
}

.bg-waiting-img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../assets/waiting-img.png");
  height: 350px;
}

.vue-pdf-embed {
  margin-top: 10px;
  border-radius: 0.25rem;
  overflow: hidden;
  width: 400px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

@media (max-width: 820px) {
  .text-arrow {
    display: none !important;
  }
}
</style>
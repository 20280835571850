var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-hidden hide-scrollbar",staticStyle:{"height":"calc(100vh - 72px)"}},[(_vm.selectedPrescription != null)?_c('div',{staticClass:"bg-c-primary",attrs:{"id":"printing"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"p-3 position-relative d-block"},[_c('button',{staticClass:"\n            btn\n            text-white\n            position-absolute\n            btn-sm\n            floating-btn\n            d-flex\n            text-arrow\n          ",attrs:{"type":"button"},on:{"click":_vm.handleBack}},[_c('i',{staticClass:"ri-arrow-left-line mr-2 align-middle"}),_vm._v("Back ")]),_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"text-white mb-0 ff-title fw-strong"},[_vm._v(" E-prescription "+_vm._s(_vm.selectedPrescription.prescription_name)+" ")])])])])]):_vm._e(),_c('div',{staticClass:"bg"},[_c('div',{staticClass:"\n        overflow-hidden\n        w-100\n        d-flex\n        justify-content-center\n        align-items-start\n        p-3\n      ",staticStyle:{"height":"70px"}},[_c('div',[_c('div',{staticClass:"mb-4 text-center",attrs:{"id":"printing"}},[(_vm.selectedPrescription && _vm.selectedPrescription.consultation)?_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":"Basic example"}},[(
                _vm.selectedPrescription.consultation.prescriptions.length > 0
              )?_c('button',{class:_vm.selectedTab == 'Prescription'
                  ? 'btn btn-danger btn-c-primary fw-strong'
                  : 'btn btn-outline-danger btn-outline-c-primary',attrs:{"type":"button"},on:{"click":function($event){_vm.selectedTab = 'Prescription'}}},[_vm._v(" Prescription ")]):_vm._e(),(
                _vm.selectedPrescription.consultation.medical_certificates
                  .length > 0
              )?_c('button',{class:_vm.selectedTab == 'Medical Certificate'
                  ? 'btn btn-danger btn-c-primary fw-strong'
                  : 'btn btn-outline-danger btn-outline-c-primary',attrs:{"type":"button"},on:{"click":function($event){_vm.selectedTab = 'Medical Certificate'}}},[_vm._v(" Medical Certificate ")]):_vm._e(),(
                _vm.selectedPrescription.consultation.referral_letters.length > 0
              )?_c('button',{class:_vm.selectedTab == 'Referral Letter'
                  ? 'btn btn-danger btn-c-primary fw-strong'
                  : 'btn btn-outline-danger btn-outline-c-primary',attrs:{"type":"button"},on:{"click":function($event){_vm.selectedTab = 'Referral Letter'}}},[_vm._v(" Referral Letter ")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"\n        overflow-auto\n        w-100\n        d-flex\n        justify-content-center\n        align-items-start\n        px-3\n      ",staticStyle:{"height":"calc(100vh - 204px)","padding-bottom":"4rem"}},[(!_vm.hasPaid)?_c('div',[_c('div',{staticClass:"responsive-container d-flex justify-content-center"},[_c('div',{staticClass:"text-center mb-4"},[(_vm.selectedPrescription != null)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body border-bottom"},[_vm._m(0),_vm._m(1),_c('button',{staticClass:"\n                    btn\n                    mb-3\n                    btn-primary\n                    w-50\n                    button-primary-color-bg\n                    custom-radius\n                  ",on:{"click":_vm.handlePayment}},[_vm._v(" Pay Now ")])])]):_vm._e()])])]):_c('div',[(_vm.selectedTab == 'Referral Letter')?_c('div',[(
              _vm.selectedPrescription &&
              _vm.selectedPrescription.consultation &&
              !_vm.selectedPrescription.consultation.referral_letters.length &&
              !_vm.selectedPrescription.consultation.referral_letters[0]
                .referral_letter_file
            )?_c('div',{staticClass:"card border-0 custom-radius shadow-sm",staticStyle:{"max-width":"720px"}},[_vm._m(2)]):_c('div',[_c('button',{staticClass:"btn btn-c-primary text-white mb-2 w-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.download(
                  _vm.selectedPrescription.consultation.referral_letters[0]
                    .referral_letter_file.url,
                  'referralLetter.pdf'
                )}}},[_vm._v(" Download PDF ")]),(_vm.referralLetterPDF)?_c('vue-pdf-embed',{attrs:{"source":_vm.referralLetterPDF}}):_vm._e()],1)]):_vm._e(),(_vm.selectedTab == 'Medical Certificate')?_c('div',[(
              _vm.selectedPrescription &&
              _vm.selectedPrescription.consultation &&
              _vm.selectedPrescription.consultation.medical_certificates.length &&
              !_vm.selectedPrescription.consultation.medical_certificates[0]
                .medical_certificate_file
            )?_c('div',{staticClass:"card border-0 custom-radius shadow-sm",staticStyle:{"max-width":"720px"}},[_vm._m(3)]):_c('div',[_c('button',{staticClass:"btn btn-c-primary text-white mb-2 w-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.download(
                  _vm.selectedPrescription.consultation.medical_certificates[0]
                    .medical_certificate_file.url,
                  'medicalCert.pdf'
                )}}},[_vm._v(" Download PDF ")]),(_vm.medicalCertPDF)?_c('vue-pdf-embed',{attrs:{"source":_vm.medicalCertPDF}}):_vm._e()],1)]):_vm._e(),(_vm.selectedTab == 'Prescription')?_c('div',[(
              _vm.selectedPrescription && !_vm.selectedPrescription.prescription_file
            )?_c('div',{staticClass:"card border-0 custom-radius shadow-sm",staticStyle:{"max-width":"720px"}},[_vm._m(4)]):_c('div',[_c('button',{staticClass:"btn btn-c-primary text-white mb-1 w-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.download(
                  _vm.selectedPrescription.prescription_file.url,
                  'prescription.pdf'
                )}}},[_vm._v(" Download PDF ")]),(_vm.prescriptionPDF)?_c('vue-pdf-embed',{attrs:{"source":_vm.prescriptionPDF}}):_vm._e(),(_vm.selectedPrescription.orders && _vm.selectedPrescription.orders.length > 0)?_c('button',{staticClass:"btn btn-c-primary text-white mt-3 w-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.gotoOrderPage(_vm.selectedPrescription.orders[_vm.selectedPrescription.orders.length - 1].id)}}},[_vm._v(" View Latest Order ")]):_vm._e()],1)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('h4',{staticClass:"mb-2 ff-title fw-strong"},[_vm._v("Why wait?")]),_c('p',{staticClass:"mb-4 text-muted ff-desc"},[_vm._v(" You can access your prescriptions right here. Only after making a payment may you access your prescription, medical certificate, and referral letter, if any. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('p',[_vm._v(" Only RM 15.00 for your expert consultation ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',[_c('div',{staticClass:"bg-waiting-img"}),_c('h5',{staticClass:"ff-desc"},[_vm._v(" Please bear with us while the doctor is preparing your related documents. ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',[_c('div',{staticClass:"bg-waiting-img"}),_c('h5',{staticClass:"ff-desc"},[_vm._v(" Please bear with us while the doctor is preparing your related documents. ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',[_c('div',{staticClass:"bg-waiting-img"}),_c('h5',{staticClass:"ff-desc"},[_vm._v(" Please bear with us while the doctor is preparing your related documents. ")])])])])}]

export { render, staticRenderFns }